export enum Alignment {
    Left,
    Right,
    Center
}

export enum Size {
    Small,
    Medium,
    Large
}

export enum Spacing {
    Left,
    Right,
    Top,
    Bottom
}