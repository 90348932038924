import React, { Component } from "react"
import "./header.less"

class Header extends Component {
  render() {
    return <div className="c-header">{this.props.children}</div>
  }
}

export default Header
