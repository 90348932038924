class NavigationItem {
  name: string
  link: string // relates to the page

  constructor(name: string, link: string) {
    this.name = name
    this.link = link
  }
}

export default NavigationItem
