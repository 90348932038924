import React, { Component } from "react"
import NavigationItem from "../navigation/NavigationItem"
import { Link } from "gatsby"
import "./navigation.less"

class NavigationProps {
  items: NavigationItem[]
}

class Navigation extends Component<NavigationProps> {
  render() {
    return (
      <div className="c-navigation__container">
        <ul className="c-navigation">
          {this.props.items.map(item => {
            return (
              <li
                key={item.name}
                className="c-navigation__item t-medium h-link "
              >
                <Link to={item.link} activeClassName="active">
                  {item.name}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

export default Navigation
