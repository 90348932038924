import React, { Component } from "react"
import "./footer.less"
import FooterItemData from "./FooterItemData";
import { Size, Alignment, Spacing } from "../utils/Utils";

class FooterItemProps {
    content: FooterItemData
  }

class FooterItem extends Component<FooterItemProps> {
  render() {
    return <div className={"c-footer__item " +
    (this.props.content.size === Size.Large ?
        "c-footer__item--large" :
        this.props.content.size === Size.Medium ?
        "c-footer__item--medium" : "c-footer__item--small") + 
        (this.props.content.alignment === Alignment.Left ?
            " t-left" : this.props.content.alignment === Alignment.Center ?
            " t-center" : "t-right") +
            (this.props.content.margin === Spacing.Left ?
              " l-margin-left-small" :
              this.props.content.margin === Spacing.Right ?
              " l-margin-right-small" : "")}>
        {this.props.content.content}</div>
  }
}

export default FooterItem
