import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header/Header"
import Footer from "./footer/Footer"
import "./layout.less"
import Navigation from "./navigation/Navigation"
import NavigationItem from "./navigation/NavigationItem"
import FooterItem from "./footer/FooterItem";
import FooterItemData from "./footer/FooterItemData";
import { Size, Alignment, Spacing } from "./utils/Utils";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)

  /* Navigation */
  const menuItems: NavigationItem[] = data.site.siteMetadata.menuLinks.map(
    link => {
      return new NavigationItem(link.name, link.link)
    }
  )

  return (
    <div className="c-container">
      <Header>
        <Navigation items={menuItems} />
      </Header>
      {children}
      <Footer>
        <FooterItem content={footer1}></FooterItem>
        <FooterItem content={footer2}></FooterItem>
        <FooterItem content={footer3}></FooterItem>
      </Footer>
    </div>
  )
}

/* temporary footer */
const today = new Date();
const footer1 = new FooterItemData("HP 200/200", Size.Small, Alignment.Left, Spacing.Right);
const footer2 = new FooterItemData(today.toDateString(), Size.Medium, Alignment.Center, null);
const footer3 = new FooterItemData(today.toLocaleTimeString(), Size.Small, Alignment.Right, Spacing.Left);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
