import { Size, Alignment, Spacing } from "../utils/Utils";

class FooterItemData {
  content: string;
  size: Size;
  alignment: Alignment;
  margin: Spacing;

  constructor(content: string, size: Size, alignment: Alignment, margin: Spacing) {
    this.content = content
    this.size = size
    this.alignment = alignment
    this.margin = margin
  }
}

export default FooterItemData
