import React, { Component } from "react"
import "./footer.less"

class Footer extends Component {
  render() {
    return <div className="c-footer">{this.props.children}</div>
  }
}

export default Footer
